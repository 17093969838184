import Vue from "vue";
import Components from "./components";
import "./app.css";

import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data() {
    return {
      activeNav: false,
      hidePopup: !!sessionStorage.hidePopup
    }
  },
  methods: {
    hidePopupClick() {
      this.hidePopup = true;
      sessionStorage.hidePopup = true;
    }
  }
});
